import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { TextFieldProps } from './registro-utils'
import { isPhone } from 'spain-phone'

const Detalles = ({setPartialRegister, children}) => {

  const [showPassword, setShowPassword] = useState(false)
  const [detalles, setDetalles] = useState({
    email: '',
    tel: '',
    password: '',
  })

  const handleChange = prop => event => setDetalles({ ...detalles, [prop]: event.target.value.toString() })
  // eslint-disable-next-line
  React.useEffect(() => setPartialRegister(detalles), [detalles])

  return (
    <>
      <TextField
        required
        type="email"
        value={detalles.email}
        onChange={handleChange("email")}
        label="Correo electrónico"
        {...TextFieldProps}
        autoComplete="email"
      />
      <TextField
        {...TextFieldProps}
        error={!isPhone(detalles.tel)}
        value={detalles.tel}
        onChange={handleChange("tel")}
        label="Teléfono"
        type="tel"
        title="Introduzca su teléfono"
        inputProps={{
          pattern: "^[0-9]{2,3}-? ?[0-9]{6,7}$",
          minLength: 9,
        }}
      />

      <TextField
        required
        {...TextFieldProps}
        style={{ marginBottom: "2em" }}
        value={detalles.password}
        onChange={handleChange("password")}
        label="Contraseña"
        type={showPassword ? "text" : "password"}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Enseñar o esconder contraseña"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {children}
    </>
  );
}

export default Detalles
