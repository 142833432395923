import React, { useState } from 'react'
import { useSessionWhichRole } from 'backend/auth'
// import moment from 'moment'
import { Dialog, DialogTitle, Box, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { FixedLinearProgress } from 'uno-material-ui'
import CitaData from './CitaData'
import CitaForm from './CitaForm'

const Cita = ({ current: event, open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [user] = useSessionWhichRole()

  // When we submit
  const isSubmitted = () => onClose('📅 Nueva cita!', 'success')

  if(!user || !event) return null

  // const isCita = event.type === 'cita'
  // const isNewCita = event.type !== 'cita'

  console.log('YOLO', event)

  const canEdit = !event.confirm || (event.confirm && !event.confirm.xEspecialista)

  const CitaHeader = ({title, start, end}) => (
    <DialogTitle>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span>
          <strong>{title}</strong>
          {(start && end) && `${start} - ${end}`}
        </span>
        <div>
          <IconButton aria-label="close" size="medium" onClick={() => onClose()}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      </Box>
    </DialogTitle>
  )

  const CitaBox = () => {
    return ( user.isCliente && !user.isEspecialista && canEdit)
    ? <CitaForm event={event} isSubmitted={isSubmitted} Header={CitaHeader} onClose={onClose} setLoading={setLoading} />
    : <CitaData values={event} Header={CitaHeader} onClose={onClose} setLoading={setLoading} />
  }

  return (
    <>
      <FixedLinearProgress isLoading={loading} />

      <Dialog open={open} onClose={onClose} scroll="body">
        <CitaBox />
      </Dialog>
    </>
  )
}

export default Cita
