import React, { useState, useEffect } from 'react'
import { updateEntry, deleteEntry } from 'config/fb'
import Datetime from 'react-datetime'
import { useSnackbar } from "notistack";


import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, Button, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { timeConstraints } from 'utils/cal-utils'

const EditEvent = ({ current = {}, open, onClose}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [event, setEvent] = useState(current);

  const [, setWhatIsFocused] = useState('start');

  // Avoid memo and rerendering
  useEffect(() => setEvent(current), [current])

  useEffect(() => {
    window.addEventListener(
      "keydown",
      function (e) {
        if (e.defaultPrevented) {
          return; // Do nothing if the event was already processed
        }

        if (['Backspace', 'Delete', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
          enqueueSnackbar('Por favor, utiliza las flechas', {
            variant: 'warning',
          })
          e.preventDefault()
        }
        // if (
        //   ["ArrowDown"].includes(e.key)
        // ) {
        //   console.log(whatIsFocused);
        //   if (['start', 'end'].includes(whatIsFocused)) {
        //     const time = event[whatIsFocused]
        //     const newTime = new Date(time.setMinutes(time.getMinutes() - 30))
        //     setEvent({ ...event, [whatIsFocused]: newTime });
        //   }

        //   enqueueSnackbar("Por favor, utiliza las flechas", {
        //     variant: "warning",
        //   });

        //   e.preventDefault();
        // }
      },
      true
    );
  }, [enqueueSnackbar]);

  const updateEvent = (event) => updateEntry(event.id, { ...event }, 'disponibilidad')

  const handleSubmit = e => {
    e.preventDefault()
    updateEvent(event)
    onClose('📅 Modificada disponibilidad', 'success')
  }

  const onDeleteEvent = () => {
    onClose('📅 Evento eliminado', 'success')
    deleteEntry(event.id, 'disponibilidad')
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <span>{event.title}</span>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => onClose()}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" alignItems="center">
            <Box>
              <Datetime
                id="date-start"
                renderInput={RenderInput}
                value={event.start}
                dateFormat={false}
                inputProps={{
                  label: "Inicio",
                  style: { caretColor: "transparent" },
                }}
                onFocus={() => setWhatIsFocused("start")}
                onChange={(e) => setEvent({ ...event, start: e.toDate() })}
                timeConstraints={timeConstraints}
              />
            </Box>
            <Box ml={2}>
              <Datetime
                id="date-end"
                renderInput={RenderInput}
                value={event.end}
                dateFormat={false}
                inputProps={{
                  label: "Fin",
                  style: { caretColor: "transparent" },
                }}
                onFocus={() => setWhatIsFocused("end")}
                onChange={(e) => setEvent({ ...event, end: e.toDate() })}
                timeConstraints={timeConstraints}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={onDeleteEvent}>
            Eliminar
          </Button>

          <Button color="primary" type="submit">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const RenderInput = ( props ) => (
  <TextField
    variant="outlined"
    margin="normal"
    id="date"
    name="date"
    {...props}
  />
)

export default EditEvent
