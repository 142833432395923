import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const NewUser = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="256.596" y1="513.229" x2="256.596" y2="1.228" gradientTransform="matrix(.9981 0 0 -.9981 -.096 512.727)"><stop offset="0" stopColor="#2af598" /><stop offset="1" stopColor="#009efd" /></linearGradient><path d="M403.212 148.211C403.212 66.763 336.948.499 255.501.499S107.789 66.763 107.789 148.211c0 50.449 25.429 95.065 64.137 121.724-36.139 12.471-69.263 33.071-97.091 60.899C26.577 379.093 0 443.254 0 511.501h39.922c0-118.871 96.708-215.579 215.579-215.579 81.448 0 147.711-66.264 147.711-147.711zM255.501 256c-59.435 0-107.789-48.354-107.789-107.789s48.354-107.79 107.789-107.79S363.29 88.775 363.29 148.211 314.936 256 255.501 256zM512 427.665h-83.836v83.836h-39.922v-83.836h-83.836v-39.922h83.836v-83.836h39.922v83.836H512v39.922z" fill="url(#a)" /></svg>
  </SvgIcon>
)

export default NewUser
