import React, { useState } from 'react'
import { signOut, useSessionWhichRole } from 'backend/auth'
import { useLocation } from 'wouter'
import { makeStyles } from '@material-ui/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import { SupervisedUserCircle as User, PowerSettingsNew as LogOut } from '@material-ui/icons'
import DoctorIcon from 'components/UI/Icons/DoctorIcon'
import HospitalIcon from 'components/UI/Icons/Hospital'
import NewUser from 'components/UI/Icons/NewUser'
import Calendar from 'components/UI/Icons/Calendar'

const useStyles = makeStyles(theme => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}))


const SuperNav = () => {
  const [user] = useSessionWhichRole()
  const [, setLocation] = useLocation()
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const adminNav = [
    { icon: <LogOut fontSize="inherit" />, name: 'Cerrar sesión', click: () => signOut() },
    { icon: <NewUser fontSize="inherit" />, name: 'Nuevo usuario', click: () => setLocation('/invitados') },
    { icon: <HospitalIcon />, name: 'Clientes', click: () => setLocation('/lista/clientes') },
    { icon: <DoctorIcon />, name: 'Especialistas', click: () => setLocation('/lista/especialistas') },
    { icon: <Calendar />, name: 'Calendario', click: () => setLocation('/calendario') },
  ]

  const userNav = [
    {
      icon: <User fontSize="inherit" />,
      name: "Perfil",
      click: () => setLocation("/perfil"),
    },
    {
      icon: <Calendar />,
      name: "Calendario",
      click: () => setLocation("/calendario"),
    },
    {
      icon: <LogOut fontSize="inherit" />,
      name: "Cerrar sesión",
      click: () => signOut(),
    },
  ];

  let actions = user.isAdmin ? adminNav : userNav


  const handleClick = () => setOpen(prevOpen => !prevOpen);
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      // hidden={hidden}
      icon={<SpeedDialIcon />}
      onBlur={handleClose}
      onClick={handleClick}
      onClose={handleClose}
      onFocus={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      open={open}
      direction="up"
    >
      {actions.map(({name, icon, click}) => (
        <SpeedDialAction
          key={name}
          icon={icon}
          tooltipTitle={name}
          onClick={click}
        />
      ))}
    </SpeedDial>
  )
}

export default SuperNav
