import React from 'react'
import { Box } from '@material-ui/core'
import Spinner from 'react-spinkit'



const IndoSpinner = () => {
  return (
    <Box
      display="flex"
      width="100%" height="100%"
      position="fixed"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner name="cube-grid" color="#09837d" style={{ transform: 'scale(2)' }} />
    </Box>
  )
}

export default IndoSpinner
