import React from 'react'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'

const getVar = (value) => getComputedStyle(document.documentElement).getPropertyValue(value)
const states = [
  {
    nombre: 'Disponibilidad',
    subtitulo: 'del especialista',
    color: getVar('--disponibilidadColor')
  },
  {
    nombre: 'Cita nueva',
    subtitulo: 'por cliente',
    color: getVar('--citaColor'),
  },
  {
    nombre: 'Cita aceptada',
    subtitulo: 'por especialista',
    color: getVar('--nicePink'),
  },
  {
    nombre: 'Cita confirmada',
    subtitulo: 'por cliente',
    color: getVar('--successColor'),
  },
  {
    nombre: 'Cita rechazada',
    subtitulo: 'por especialista',
    color: getVar('--errorColor'),
  }
]

const Avatar = styled.div`
  margin-right: 8px;
  width: 0;
  height: 0;
  padding: 1em;
  border-radius: 100%;
  background-color: ${props => props.color};
`

const Color = ({estado: { nombre, subtitulo, color }, isHovering}) => (
  <Box mr={2} display="flex" alignItems="flex-start">
    <Avatar color={color} />
      <Box>
        <Typography variant="caption">{nombre}</Typography>
        <br/>
        {
          subtitulo && <Typography variant="caption" className="subtitulo">{subtitulo}</Typography>
        }
      </Box>
  </Box>
)

export default function Leyenda() {
  const [estados] = React.useState(states)

  return (
    <Box p={4} display="flex" justifyContent="flex-start">
      {
        estados.map(estado => {
          return <Color key={estado.nombre} estado={estado} />
        })
      }
    </Box>
  )
}
