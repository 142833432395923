import React from 'react'
import { Link, Redirect } from 'wouter'

import { loginWithEmail, useSession } from 'backend/auth'

import { Button, TextField, InputAdornment, IconButton, Link as TypeLink, Box } from '@material-ui/core'
import { FixedLinearProgress } from 'uno-material-ui'
import { useSnackbar } from 'notistack'
import IndoHeader from 'components/UI/IndoHeader'
import IndoContainer from 'components/UI/IndoContainer'

import { Visibility, VisibilityOff } from '@material-ui/icons'

const IniciarSesion = (props) => {
  const user = useSession()
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [credentials, setCredentials] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  })

  const handleChange = prop => event => {
    setCredentials({ ...credentials, [prop]: event.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await loginWithEmail(credentials.email, credentials.password)
    } catch(err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    setLoading(false)
  }

  const handleClickShowPassword = () => {
    setCredentials({ ...credentials, showPassword: !credentials.showPassword })
  }

  // Render
  if (user) return (<Redirect to='/calendario' />)

  return (
    <IndoContainer maxWidth="xs">
      <FixedLinearProgress isLoading={loading} />
      <IndoHeader icon="lock" title="Iniciar Sesión" />
      {user && user.displayName}

      <form onSubmit={handleSubmit}>
        <TextField
          onChange={handleChange('email')}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          onChange={handleChange('password')}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          value={credentials.password}
          type={credentials.showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (<InputAdornment position="end">
            <IconButton
              aria-label="Enseñar o esconder contraseña"
              onClick={handleClickShowPassword}
              onMouseDown={(e) => e.preventDefault()}
            >
              {credentials.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>)
          }}
        />
        <Box mt={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
          >
            Iniciar Sesión
          </Button>
        </Box>
      </form>
      <Box pt={4} pb={4} display="flex" component="footer" justifyContent="space-between">
        <Link href="/no-password">
          <TypeLink variant="body2">Olvidaste tu contraseña?</TypeLink>
        </Link>
        <Link href="/registro">
          <TypeLink variant="body2">No tienes cuenta? Crea una aquí!</TypeLink>
        </Link>
      </Box>
    </IndoContainer>
  )
}

export default IniciarSesion
