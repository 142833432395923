import React from 'react'
import omit from 'lodash.omit'
import { Container, Box, Paper } from '@material-ui/core'

const IndoContainer = (props) => {
  const ContainerProps = omit(props, ['paper', 'full'])
  return (
    <Container component="main" className={props.full && 'FullContainer'} {...ContainerProps}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        height="100%"
      >
        {
          props.paper
            ? <Paper component={Box} p={2} children={props.children} />
            : props.children
        }
      </Box>
    </Container>
  )
}

export default IndoContainer
