import React from 'react'
import { Redirect } from 'wouter'
import { useConfirm } from "material-ui-confirm"
import { createUserWithEmail, addUserDetails } from 'backend/auth'
import { useSession } from '../../../backend/auth'
import omit from 'lodash.omit'
import { makeStyles } from '@material-ui/styles'
import { Stepper, Step, StepLabel, Button, Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ColorlibStepIcon from './Registro-Icon'
import ColorlibConnector from './Registro-Connector'
import IndoHeader from 'components/UI/IndoHeader'
import Direccion from './Registro-Direccion'
import Detalles from './Registro-Detalles'
import { values } from './registro-utils'

// Demo
import { useHotkeys } from 'react-hotkeys-hook'

const useStyles = makeStyles(theme => ({
  backButton: { marginRight: theme.spacing(1), },
  formBox: { maxWidth: '400px' }
}))

const Registro = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const user = useSession()
  const confirm = useConfirm()

  // Stepper
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = ['Dirección', 'Detalles']

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)
  const handleReset = () => setActiveStep(0)

  const [register, setRegister] = React.useState({})

  const setPartialRegister = (partial) => setRegister({ ...register, ...partial })

  // Demo
  useHotkeys('r+u+n', () => {
    setRegister({
      name: 'Demo',
      address: 'Demo st',
      email: 'demo@de.mo',
      tel: '687561561',
      password: '1234567890',
    })
    setActiveStep(steps.length)
  })
  const hideIt = (what = '') => Array.from(what.toString()).map(char => '*').join('')

  // Confirm!
  const confirmar = async () => {
    await confirm({
      title: 'Condiciones legales',
      description: 'En virtud de lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales, ANESAFE SERVICIOS MÉDICOS INTEGRALES, S.L. le informa que sus datos personales incorporados en este formulario, serán incluidos en un fichero creado bajo nuestra responsabilidad, con la finalidad de comunicarnos con usted para llevar a cabo el mantenimiento y control de la relación de negocio que nos vincula. Según el Reglamento (UE) 2016/679 de 27 de abril de 2016, puede ejercitar los derechos de acceso, rectificación, oposición y supresión dirigiéndose por escrito a ANESAFE SERVICIOS MÉDICOS INTEGRALES, S.L.,  C/ Monasterio de Liébana, nº 3C  1ºB 28049 Madrid, o al correo electrónico indolora.es@gmail.com.',
      dialogProps: {
        maxWidth: 'sm',
        scroll: 'paper'
      },
      confirmationText: "Estoy de acuerdo",
      cancellationText: "Cancelar",
    })
    registrar()
  }

  // Registro!

  const registrar = async () => {
    // Here!
    const details = omit(register, 'password')
    try {
      const result = await createUserWithEmail(register.email, register.password)
      await addUserDetails(result.user.uid, details)
    } catch(err) {
      enqueueSnackbar(err.message, {variant: 'error'})
    }
  }

  if (user) return <Redirect to="/perfil" />

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box width="xs" className={classes.formBox}>
        <IndoHeader title="Registro" icon={activeStep === steps.length} />

        {activeStep !== steps.length ? (
          <>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            { activeStep === 0 &&(
              <Direccion setPartialRegister={setPartialRegister}>
                <Box display="flex" justifyContent="flex-end" component="footer">
                  <Button disabled>Volver</Button>
                  <Button variant="contained" color="primary" onClick={handleNext}>Seguir</Button>
                </Box>
              </Direccion>
            )}
            { activeStep === 1 && (
              <Detalles setPartialRegister={setPartialRegister}>
                 <Box display="flex" justifyContent="flex-end" component="footer">
                  <Button onClick={handleBack}>Volver</Button>
                  <Button variant="contained" color="primary" onClick={handleNext}>Terminar</Button>
                </Box>
              </Detalles>
            )}
          </>
        ) : (
          <Box display="flex" flexDirection="column" component="footer" p={4}>
            <Table>
              <TableBody>
                {Object.keys(values).map(key => {
                  return (
                    <TableRow key={key}>
                      <TableCell><strong>{values[key]}</strong></TableCell>
                      <TableCell align="right">{key !== 'password' ? register[key] || '' : hideIt(register[key]) || ''}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button onClick={handleReset}>Reiniciar</Button>
              <Button variant="contained" color="primary" onClick={() => confirmar()}>Enviar</Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Registro
