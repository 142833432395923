import React, { useState, useEffect } from 'react'
import loadGoogleMapsApi from 'load-google-maps-api'
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import { Box } from '@material-ui/core'

const Places = ({ setSuggestion }) => {
  const [apiLoaded, setApiLoaded] = useState(false)

  useEffect(() => {
    async function fetchMaps() {
      await loadGoogleMapsApi({
        key: process.env.REACT_APP_GAPI_KEY,
        libraries: ['places'],
        region: 'ES'
      })
    }

    fetchMaps().then(() => setApiLoaded(true))
  }, [apiLoaded])



  const onSuggestionSelected = async (suggestion) => {
    try {
      const result = await geocodeByPlaceID(suggestion.place_id)
      setSuggestion({
        name: suggestion.structured_formatting.main_text,
        address: result[0].formatted_address,
        suggestion,
      })
    } catch(err) { console.log(err) }
  }

  if(!apiLoaded) return null

  return (
    <MUIPlacesAutocomplete
      onSuggestionSelected={onSuggestionSelected}
      // onSuggestionSelected={() => console.log('hola')}
      renderTarget={() => (<Box maxWidth="xs" />)}
      textFieldProps={{
        name: 'gplaces',
        variant: 'outlined',
        margin: 'normal',
        label: 'Busque su  clínica',
        fullWidth: true,
        required: true,
        autoFocus: true
      }}
    />
  )
}

export default Places
