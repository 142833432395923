import React, { useState, useContext, useEffect } from 'react'
import { useFirestoreCollectionData, useFirestore } from 'reactfire'
import firebase, { createEntry, getEntry, updateEntry, updateField, deleteEntry } from 'config/fb'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useSessionWhichRole } from './auth'
import { filteredByDuplicates } from './calFunctions'
import omit from 'lodash.omit'
// import moment from 'moment'



// Cal

export const CalContext = React.createContext([])
export const useCal = () => useContext(CalContext)

const useDisponibilidad = () => {
  const [disp, setDisp] = useState([])
  const [user] = useSessionWhichRole()
  // const disponibilidad = useFirestore().collection('disponibilidad').orderBy('start', 'desc');
  const dispRef = useFirestore().collection('disponibilidad')
  const getDisp = useFirestoreCollectionData(dispRef, { idField: 'id' })

  useEffect(() => {
    let disp = setEmStraight(getDisp, 'disponibilidad');
    disp = disp.filter(ev => !ev.hasOwnProperty('lock'))

    if (user) {
      if (user.isCliente) {
        // Filter by lock
        disp = disp.filter(ev => ev.lockFor !== user.email)
        disp = filteredByDuplicates(disp)
      }

      if (user.isEspecialista) {
        disp = disp.map(disp => {
          if (disp.email !== user.email) {
            disp.title = `${disp.title}: ${disp.email}`
          }
          return disp
        })
      }

      if (user.isAdmin) {
        disp = disp.map(disp => ({
          ...disp,
          title: `${disp.title}: ${disp.email}`
        }))
      }
    }

    console.log('Esto vemos', disp, user)
    setDisp(disp)
  }, [getDisp, user])

  return disp
}

const useCitas = () => {
  const [citas, setCitas] = useState([])
  const disp = useDisponibilidad()
  const [user] = useSessionWhichRole()

  const citasRef = useFirestore().collection('citas')
  const getCitas = useFirestoreCollectionData(citasRef, { idField: 'id' })

  useEffect(() => {
    let citas = setEmStraight(getCitas, 'cita');
    if (user) {
      if (user.isEspecialista) {
        citas = citas.filter(cita => {
          if (cita.confirm && cita.confirm.cancel && cita.confirm.cancel[user.email]) return false
          if (cita.especialista && cita.especialista !== user.email) return false
          else return true
        })
      }
      if (user.isCliente) {
        // Filtrar citas por cliente
        citas = citas.filter(cita => cita.cliente === user.email)
      }
    }
    if (disp.length > 0) citas = citas.map(cita => {
      if (!cita.slot) return cita
      if (disp && cita.slot) {
        const slot = disp.find(disp => disp.id === cita.slot)
        return {
          ...cita,
          slot
        }
      }
      else return false
    })
    setCitas(citas)
  }, [getCitas, user, disp]);

  return citas
}

export const CalProvider = ({children}) => {
  const citas = useCitas()
  const disp = useDisponibilidad()
  const events = [...disp, ...citas]

  return <CalContext.Provider value={[events]}>{children}</CalContext.Provider>;
}

export const useDisp = (id) => {
  const events = useDisponibilidad()
  console.log(events, id, events.find(ev => ev.id === id))
  return events.find(ev => ev.id === id)
}

export function useInvitados() {
  const [value, loading, error] = useCollection(
    firebase.firestore().collection('invitados'),
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  return [value, loading, error]
}





// Functions

const getCita = async (id) =>{
  const req = await getEntry(id, 'citas')
  const cita = { id, ...req.data() }
  return cita
}

const prepareEventForCallFunctions = (event) => ({
  ...event,
  start: event.start.toDate().toString(),
  end: event.end.toDate().toString()
})

export const setEmStraight = (events, type) => events.map(event => ({ ...formatRawEvent(event), type }));
export const formatRawEvent = (data, id) => ({
  ...data,
  start: data.start.toDate(),
  end: data.end.toDate(),
  ...(id && {id}),
  // ...(data.id && {id: data.id})
  // start:  weirdDate(data.start),
  // end:  weirdDate(data.end)
})

const toggleDisponibilidadLock = (ids, lock) => {
  let locks = []
  return new Promise((res, rej) => {
    try {
      ids.forEach(async id => {
        console.log(id)
        const fullSlot = await getEntry(id, 'disponibilidad')
        await updateEntry(id, { ...fullSlot.data(), lock }, 'disponibilidad')
        console.log(`📅 ${lock ? 'Desbloqueada' : 'Bloqueada'} Disponibilidad con ID: ${id}`)
        locks.push(fullSlot)
      })
      res()
    } catch (err) {
      console.log(err)
      rej(err)
    }
  })

}



// 0: Crear disponibilidad

export const buildEventFromSlot = ({start, end, title = 'Disponibilidad'}, email) => ({
  allDay: false,
  start,
  end,
  title,
  email,
  type: 'disponibilidad'
})

export const createSlot = async (data, email='') => {
  const item = await createEntry(buildEventFromSlot(data, email), 'disponibilidad')
  console.log('📅 Creado Disponibilidad con id', item.id)
  await updateField(item.id, 'id', item.id, 'disponibilidad')
  const event = await getEntry(item.id, 'disponibilidad')
  console.log('📅 Full event: ', event.data())
  return formatRawEvent(event.data(), item.id)
}

export const createEvent = async (data) => {
  // Creamos ITEM
  const item = await createEntry( data, 'citas')
  console.log('📅 Creado Evento con id', item.id)

  // Esperamos el ITEM de vuelta
  const event = await getEntry(item.id, 'citas')
  console.log('📅 Full event: ', event.data())

  // Devolvemos el item tal cual
  return formatRawEvent(event.data(), item.id)
}

export const deleteEvent = async (data) => {
  console.log(1, data)
  data.slot = data.slot.id
  try {
    // Desbloqueamos disponibilidad
    await toggleDisponibilidadLock([data.slot], false)

    const fullSlot = await getEntry(data.slot, 'disponibilidad')
    await updateEntry(data.slot, {...fullSlot.data(), lock: false}, 'disponibilidad')
    console.log('📅 Desbloqueada Disponibilidad con ID: ', data.slot)

    await deleteEntry(data.id, 'citas')

    // Avisamos
    const onDelete = firebase.functions().httpsCallable('onDelete')
    await onDelete(prepareEventForCallFunctions(data))
  } catch(err) {
    return err.message
  }
}

// 1: Crear cita

export const buildCita = (cita) => ({
  ...omit(cita, ['id', 'email']),
  type: 'cita',
  allDay: false,
  title: `Cita ${cita.cliente && `: ${cita.cliente}`}`,
  confirm: {
    xEspecialista: false,
    xAdmin: false,
    xCliente: false
  }
})

export const setOrCreateCita = async (data) => {
  if (!data.id) return createCita(data)
  const item = await getEntry(data.id, 'citas')
  return !item.exists
    ? createCita(data)
    : updateEntry(data.id, data, 'citas')
}

export const createCita = async (data) => {
  const isMulti = data.especialistas
  data.next = isMulti ? 'admin' : data.especialista

  const cita = buildCita(data)

  // Bloqueamos disponibilidad
  const lockEmUp = data.especialistas ? data.especialistas.map(esp => esp.slot) : [data.slot]
  await toggleDisponibilidadLock(lockEmUp, true)

  // Creamos ITEM
  const item = await createEntry( cita, 'citas')
  console.log('📅 Creado Evento con id', item.id)

  // Esperamos el ITEM de vuelta
  const event = await getEntry(item.id, 'citas')
  console.log('📅 Full event: ', event.data())

  // Devolvemos el item tal cual
  return formatRawEvent(event.data(), item.id)
}

// 1.5: Asignar
export const asignarEspecialistaACita = async (id, especialista) => {
  console.log('Asignando', especialista)
  try {
    const cita = await getCita(id)
    console.log(`Asignando ${especialista} a cita`, cita)

    await updateField(id, 'especialista', especialista, 'citas')
    await updateField(id, 'next', especialista.email, 'citas')
    cita.especialista = especialista
    cita.next = especialista.email

    const ourNewSlot = cita.especialistas.find(disp => disp.especialista === especialista)
    if (ourNewSlot) {
      cita.slot = ourNewSlot.slot
      await toggleDisponibilidadLock([cita.slot], cita.especialista)
    }

    const theOnesToUnlock = cita.especialistas.filter(cita => cita.especialista !== especialista)
    console.log('THE ONE', theOnesToUnlock)
    if (theOnesToUnlock) {
      theOnesToUnlock.forEach(async disp => {
        await toggleDisponibilidadLock([disp.slot], false)
      })
    }

    // Avisamos
    const onAsignarByAdmin = firebase.functions().httpsCallable('onAsignarByAdmin')
    await onAsignarByAdmin(prepareEventForCallFunctions(cita))

    return {
      status: 'success',
      message: 'Cita asignada con éxito.'
    }

  } catch (err) {
    console.log(err)
  }
}

// 2: Aceptar
export const aceptarCita = async (id) => {
  try {
    const cita = await getCita(id, 'citas')

    cita.next = cita.cliente

    cita.confirm = {
      // Optional spread... Nice!
      ...(cita.confirm && cita.confirm),
      xEspecialista: true
    }

    // Send to DB
    await updateEntry(id, cita, 'citas')

    // Email call
    const onAceptarByEspi = firebase.functions().httpsCallable('onAceptarByEspi')
    await onAceptarByEspi(prepareEventForCallFunctions(cita))


    return {
      status: 'success',
      message: 'Cita aceptada con éxito.'
    }
  } catch (err) {
    console.log(err)
  }
}

// 3.1: Rechazar
export const rechazarCita = async (id, {razones, conservarDisponibilidad, especialista, cliente}) => {
  try {
    const cita = await getCita(id, 'citas')

    await toggleDisponibilidadLock([cita.slot], cliente)

    if (!conservarDisponibilidad) {
      await deleteEntry(cita.slot, 'disponibilidad')
    } else {
      await updateField(cita.slot, 'lockFor', cita.cliente, 'disponibilidad')
    }

    console.log(1, cita)

    if (!cita.confirm) cita.confirm = {}
    if (!cita.confirm.cancel) cita.confirm.cancel = {}

    console.log(2, cita)


    cita.confirm.cancel[especialista] = razones

    // Le toca al admin
    cita.next = 'admin'
    // Send to DB
    await updateEntry(id, cita, 'citas')

    // Email call
    const onRechazarByEspi = firebase.functions().httpsCallable('onRechazarByEspi')
    await onRechazarByEspi({ cita: prepareEventForCallFunctions(cita), especialista })


    return {
      status: 'success',
      message: 'Cita rechazada con éxito.'
    }
  } catch (err) {
    return {
      status: 'error',
      message: err.message
    }
  }
}

export const confirmarCita = async (id) => {
  try {
    const cita = await getCita(id)

    console.log(1, cita)

    const onConfirmByCliente = firebase.functions().httpsCallable('onConfirmByCliente')
    await onConfirmByCliente(prepareEventForCallFunctions(cita))


    cita.next = 'admin'
    cita.confirm = {
      ...(cita.confirm && cita.confirm),
      xCliente: true
    }

    await updateEntry(id, cita, 'citas')

    return {
      status: 'success',
      message: 'Cita confirmada con éxito.',
      cita
    }

  } catch(err) {
    console.log(err)
  }
}

export const cerrarCita = async (id) => {
  try {
    if ( firebase.auth().currentUser.profile.role !== 'admin' ) {
      throw new Error ('Nop')
    }

    const cita = await getCita(id)
    cita.confirm = {
      ...(cita.confirm && cita.confirm),
      xAdmin: true
    }

    cita.next = false

    // Cita
    await updateEntry(id, cita, 'citas')

    // Email call
    const onCloseByAdmin = firebase.functions().httpsCallable('onCloseByAdmin')
    await onCloseByAdmin(prepareEventForCallFunctions(cita))
  } catch (e) {
    console.error(e.message);
  }
  return {
    status: 'success',
    message: 'Cita cerrada con éxito.'
  }
}

// const prepareEventForCalendar = (event) => ({
//   ...event,
//   start: moment(event.start.toDate()).format("MM/DD/YYYY HH:mm"),
//   end: moment(event.end.toDate()).format("MM/DD/YYYY HH:mm")
// })

export const alien = (email1, email2) => email1 !== email2
export const native = (email1, email2) => email1 === email2
