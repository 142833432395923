import React from 'react'
import moment from 'moment'
import { Box, Slider, Typography, Tooltip, FormLabel } from '@material-ui/core'
import { hourDiff } from 'utils/cal-utils'


const returnNumber = (date) => {
  let decMinutes = moment(date).minutes() === 30 ? 0.5 : 0
  return moment(date).hours() + decMinutes
}

const showText = (value) => {
  const hours = parseInt(value)
  let minutes = ((value % 1) * 60)
  return `${hours}:${minutes === 0 ? '00' : minutes}`
}
const setDate = (date, value) => date.setHours(value, (value % 1) * 60)

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={showText(value)}>
      {children}
    </Tooltip>
  );
}

export default function RangeSlider({
  min, max,
  start,
  end,
  onChange,
  onBlur,
  setUsedForm
}) {
  const [values, setValues] = React.useState(() => {
    return {
      start: returnNumber(start),
      end: returnNumber(end)
    }
  })

  const [constraints] = React.useState(() => {
    const minTime = returnNumber(min)
    const maxTime = returnNumber(max)
    return {
      min: minTime,
      max: maxTime
    }
  })

  const handleChange = (e, [start, end]) => {
    setValues({start, end})
  }

  const handleBlur = () => {
    onBlur('start', true)
  }

  const handleChangeCommitted = (e, [theStart, theEnd]) => {
    setDate(start, theStart)
    setDate(end, theEnd)

    onChange('start', start)
    onChange('end', end)

    setUsedForm()
  }

  return (
    <Box>
      <FormLabel variant="caption">Duración: {showText(hourDiff(start, end))}h</FormLabel>
      <Slider
        min={constraints.min}
        max={constraints.max}
        step={0.5}
        value={[values.start, values.end]}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        aria-labelledby="range-slider"
        onBlur={handleBlur}

        ValueLabelComponent={ValueLabelComponent}
        // valueLabelDisplay="auto"
        // getAriaValueText={showText}
        // valueLabelFormat={showText}
      />

      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="caption">{showText(constraints.min)}</Typography>
        <Typography variant="caption">{showText(constraints.max)}</Typography>
      </Box>
    </Box>
  )
}



/*
  // Original Task: get min to equal 8.5
  const returnNumber = (date) => {
    // console.log('MIN', date)

    const hours = moment(date).hours()
    // console.log('MIN HOURS', hours)

    const minutes = moment(date).minutes()
    let decMinutes = minutes === 30 ? 0.5 : 0

    // More complex:
    // let minutes = 0
    // if (minutes === 15) decMinutes = 0.25
    // if (minutes === 30) decMinutes = 0.5
    // if (minutes === 45) decMinutes = 0.75
    // And so on

    // console.log('MIN MINUTES', minutes)
    // return hours + minutes(60)

    return hours + decMinutes
  }
*/
