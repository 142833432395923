

export const TextFieldProps = {
  variant: 'outlined',
  margin: 'normal',
  required: true,
  fullWidth: true
}

export const values = {
  name: 'Nombre',
  address: 'Dirección',
  email: 'Correo',
  tel: 'Teléfono',
  password: 'Contraseña'
}
