import React from 'react'
import { Link, Redirect } from 'wouter'

import { useSession, createUserWithEmail, addUserDetails } from 'backend/auth'
import omit from 'lodash.omit'

import { TextField, Button, Box, Link as TypeLink } from '@material-ui/core'
import { FixedLinearProgress } from 'uno-material-ui'
import IndoContainer from 'components/UI/IndoContainer'
import IndoHeader from 'components/UI/IndoHeader'
import Detalles from './Registro-Detalles'
import {TextFieldProps} from './registro-utils'

const RegistroEspecialistas = () => {
  const user = useSession()

  const [loading, setLoading] = React.useState(false)
  const [register, setRegister] = React.useState({
    name: '',
    role: 'esp',
    tel: '',
    email: ''
  })
  const setPartialRegister = (partial) => setRegister({ ...register, ...partial })
  const handleChange = prop => event => setRegister({ ...register, [prop]: event.target.value.toString() })

  const onSubmit = async () => {
    setLoading(true)
    const details = omit(register, 'password')
    try {
      const result = await createUserWithEmail(register.email, register.password)
      await addUserDetails(result.user.uid, details)
    } catch(err) {
      console.log(err.message)
    }
    setLoading(false)
  }

  // Render
  if (user) return (<Redirect to='/calendario' />)

  return (
    <IndoContainer maxWidth="xs">
      <FixedLinearProgress isLoading={loading} />
      <IndoHeader icon="lock" title="Nuevo Especialista" />
      <TextField
        {...TextFieldProps}
        label="Nombre"
        value={register.name}
        onChange={handleChange('name')}
        autoFocus
      />
      <Detalles setPartialRegister={setPartialRegister} />
      <Box mt={2}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={onSubmit}
        >
          Crear cuenta
        </Button>
      </Box>
      <Box pt={4} pb={4} display="flex" component="footer" justifyContent="space-between">
        <Link href="/no-password">
          <TypeLink variant="body2">Olvidaste tu contraseña?</TypeLink>
        </Link>
        <Link href="/">
          <TypeLink variant="body2">¿Ya tienes cuenta? Inicia sesión!</TypeLink>
        </Link>
      </Box>
    </IndoContainer>
  )
}

export default RegistroEspecialistas
