import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html, body {
    /* background-color: rgba(color('primary'), .1) */
  }

  .App {
    height: 100vh;
    width: 100%;
    display: flex;
  }

  a {
    cursor: pointer;
  }

  .distHorizontal > * + * { margin-left: 1em; }
  .distVertical > * + * { margin-top: 1em; }
  .invisible { visibility: hidden; }

  .capitalize { text-transform: capitalize; }

  [data-placement="bottom-start"] { z-index: 99 }
  [data-placement="bottom-start"] .MuiPaper-root {
    max-width: 540px;
    margin: 0 auto;
  }

  @keyframes bounce {
    0%, 20%, 60%, 100% { transform: translateY(0); }
    40% { transform: translateY(-20px); }
    80% { transform: translateY(-10px); }
  }
  .bouncy { animation: bounce 1s infinite; }

  /**
    * simpleEntrance
    * - enter in simply
    */
   @keyframes simpleEntrance {
    0% {
      opacity: 0%;
      transform: translate3d(-2000px,500px,0) scale(10);
    }
    1% { opacity: 100%}
    40% { transform: translate3d(2000px,0px,0) scale(5);}
    50% { transform: translate3d(-2000px,-100px,0) scale(3);}
    60% { transform: translate3d(2000px,100px,0) scale(2);}
    70% { transform: translate3d(-2000px,400px,0) scale(.1);}
    75% { transform: translate3d(0px,0px,0) scale(.1);}
    80% { transform:  rotate(360deg) translateX(150px) rotate(-360deg)}
  }

  .simpleEntrance {
    animation-timing-function: linear;
    transform-origin: bottom center;
    animation-name: simpleEntrance;
    animation-duration: 10s;
    position: relative;
  }

  .simpleEntrance:before {
    content: '';
    position: absolute;
    left: 45%;
    top: 88%;
    background-image: url('http://www.stickpng.com/assets/images/584999a27b7d4d76317f5ffe.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 80px;
    height: 200px;
    animation: nope 3s ease forwards;
    animation-delay: 12s;
    transform: scale(2.5);
    opacity: 0;
  }


  @keyframes nope {
    0% {
      opacity: 0;
      transform: scale(2.5) translateY(-200px);
    } 50% {
      opacity: 1;
    } 100% {
      transform: scale(2.5) translateY(0);
      opacity: 1;
    }
  }

  .subtitulo {
    font-size: 0.7rem !important;
    position: relative;
    top: -4px;
    opacity: .8;
    letter-spacing: .5px;
  }
`

export default GlobalStyles
