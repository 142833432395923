import React from 'react'
import { useFirestoreCollectionData, useFirestore, useAuth } from 'reactfire'
import { deleteEntry } from "config/fb";
import { IconButton, Box, Paper, Table, TableHead, TableBody, TableCell, TableRow, Toolbar, Tooltip, Typography } from '@material-ui/core'
import DoctorIcon from 'components/UI/Icons/DoctorIcon'
import { Delete, Restore } from '@material-ui/icons'
import { useConfirm } from 'material-ui-confirm'
import { FixedLinearProgress } from 'uno-material-ui'
import { useSnackbar } from 'notistack'
import IndoContainer from 'components/UI/IndoContainer'

const Especialista = ({
  name,
  email,
  tel,
  sendResetPasswordLink,
  deleteUser,
  i,
}) => {
  return (
    <TableRow>
      <TableCell>
        <DoctorIcon />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{tel}</TableCell>

      <TableCell align="right">
        <Tooltip title="Resetear password">
          <IconButton
            size="small"
            color="secondary"
            onClick={sendResetPasswordLink}
          >
            <Restore fontSize="inherit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Eliminar usuario">
          <IconButton size="small" color="secondary" onClick={deleteUser}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const Especialistas = () => {
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()

  const auth = useAuth()
  const sendResetPasswordLink = async (email) => {
    const actionCodeSettings = {
      url: 'https://app.indolora.es',
      handleCodeInApp: true
    }

    setLoading(true)

    try {
      await confirm({
        title: `¿Quieres resetear el password de ${email}?`,
        // description: `No hemos encontrado un usuario correspondiente a ${email}`,
        dialogProps: {
          maxWidth: 'xs'
        }
      })

      await auth.sendPasswordResetEmail(email, actionCodeSettings)

      enqueueSnackbar(`Email enviado a email a ${email}`, {
        variant: 'success'
      })
    } catch (err) {
      (err) && enqueueSnackbar('No se envió el email: ' + err.message, {
        variant: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteUser = async (id, email) => {
    setLoading(true)

    try {
      await confirm({
        title: `¿Quieres eliminar el usuario con email ${email}?`,
        // description: `No hemos encontrado un usuario correspondiente a ${email}`,
        dialogProps: {
          maxWidth: 'xs'
        }
      })

      await deleteEntry(id, 'users')

      enqueueSnackbar(`Eliminado ${email}`, {
        variant: 'success'
      })
    } catch (err) {
      (err) && enqueueSnackbar('No se eliminó: ' + err.message, {
        variant: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const espRef = useFirestore().collection('users').where('role', '==', 'esp')
  const especialistas = useFirestoreCollectionData(espRef, { idField: 'id' })

  return (
    <>
      <FixedLinearProgress isLoading={loading} />
      <IndoContainer maxWidth="md">
        <Box display="flex" alignItems="center" justifyContent="stretch">
          <Paper width="100%" style={{ flex: 1 }}>
            <Toolbar>
              <Typography variant="h6" id="tableTitle">
                Lista de especialistas
              </Typography>
            </Toolbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {especialistas && especialistas.map((espi, i) => {
                  console.log(espi)
                  return (
                    <Especialista deleteUser={() => deleteUser(espi.id, espi.email)} sendResetPasswordLink={() => sendResetPasswordLink(espi.email)} key={i} {...espi} i={i} />
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </IndoContainer>
    </>
  )
}

export default Especialistas
