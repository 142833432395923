import React from 'react'
import { Link } from 'wouter'
import { Button, TextField, Link as TypeLink, Box } from '@material-ui/core'
import { forgotPassword } from 'backend/auth'

import IndoContainer from 'components/UI/IndoContainer'
import IndoHeader from 'components/UI/IndoHeader'
import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'

export default function NoPassword(props) {
  const [email, setEmail] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      await forgotPassword(email)
      enqueueSnackbar('Email enviado', { variant: 'success' })
    } catch(err) {
      enqueueSnackbar('Ha habido un error. Comprueba tu correo e inténtalo otra vez.', { variant: 'error' })
    }

    setLoading(false)
  }

  return (
    <IndoContainer maxWidth="xs">
      <FixedLinearProgress isLoading={loading} />
      <IndoHeader icon="lock" title="Restablecer contraseña" />
      <form onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Restablecer contraseña
        </Button>
        <Box pt={4} pb={4} display="flex" justifyContent="space-between">
        <Link href="/iniciar-sesion">
          <TypeLink variant="body2">¿Ya tienes cuenta? Inicia sesión!</TypeLink>
        </Link>
        <Link href="/registro">
          <TypeLink variant="body2">No tienes cuenta? Crea una aquí!</TypeLink>
        </Link>
      </Box>
      </form>
    </IndoContainer>
  )
}
