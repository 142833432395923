import moment from "moment"

export const filteredByDuplicates = (events) => {
  return events.reduce((acc, current) => {
    const duplicate = acc.find(
      item =>
        item.start.toString() === current.start.toString() &&
        item.end.toString() === current.end.toString()
    );
    const eater = acc.find(
      item =>
        moment(item.start).isAfter(moment(current.start)) &&
        moment(item.end).isBefore(moment(current.end))
    );
    const eaten = acc.find(
      item =>
        moment(item.start).isBefore(moment(current.start)) &&
        moment(item.end).isAfter(moment(current.end))
    );
    // NO FUNCIONA
    if (duplicate) {
      console.log("Found duplicate", duplicate);
      duplicate.especialista = "Por asignar";

      let especialistas = [
        // ...(duplicate.especialistas && [duplicate.especialistas] || []),
        { especialista: duplicate.email, slot: duplicate.id },
        { especialista: current.email, slot: current.id }
      ].flat();

      // especialistas = map(especialistas, x => omit(x, isundefined));

      // especialistas = especialistas.map(x => {
      //   for (const key in x) {
      //     if (x[key] === undefined) {
      //       console.log("UNDEFINED!", key, x[key]);
      //       return omit(key);
      //     }
      //   }
      // });

      especialistas = especialistas.filter(
        (a, b) => especialistas.indexOf(a) === b
      );

      duplicate.especialistas = especialistas;

      return acc;
    }

    if (eater) {
      console.log("Found eater", eater);
      current.especialistas = [
        { especialista: eater.email, slot: eater.id },
        { especialista: current.email, slot: current.id }
      ];
      return acc.filter(item => item !== eater).concat([current]);
    }

    if (eaten) {
      console.log("Found eaten", eaten);
      eaten.especialistas = [
        { especialista: eaten.email, slot: eaten.id },
        { especialista: current.email, slot: current.id }
      ];
      return acc;
    }

    return acc.concat([current]);
  }, []);
}
