import React from 'react'
import { useSession, addUserDetails } from 'backend/auth'
import { Avatar, Typography, Box, TextField, Button } from '@material-ui/core'
import IndoContainer from 'components/UI/IndoContainer'
import { SupervisedUserCircle } from '@material-ui/icons'
import { roleExtractor } from 'utils/role-extractor'
import { FixedLinearProgress } from 'uno-material-ui'
import { useSnackbar } from 'notistack'

const Perfil = () => {
  const user = useSession()
  const [userProfile, setUserProfile] = React.useState(user.profile)

  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    await addUserDetails(user.uid, userProfile)
    enqueueSnackbar('Detalles modificados.', {variant: 'success'})
    setLoading(false)
  }

  const handleChange = prop => event => {
    setUserProfile({ ...userProfile, [prop]: event.target.value })
  }

  if (!user) return null

  return (
    <IndoContainer paper="true" maxWidth="xs">
      <FixedLinearProgress isLoading={loading} />
      <form noValidate onSubmit={onSubmit}>
        <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
          { user.photoURL  ? <Avatar src={user.photoURL} /> : <Avatar><SupervisedUserCircle /></Avatar> }
          <Typography component="h1" variant="h5">{userProfile.name}</Typography>
          <Typography component="h2" variant="h6">{ roleExtractor(user.profile.role) }</Typography>
        </Box>

        <TextField
          margin="normal"
          required
          fullWidth
          label="Nombre"
          name="name"
          value={userProfile.name}
          onChange={handleChange('name')}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Teléfono"
          type="tel"
          value={userProfile.tel || ''}
          onChange={handleChange('tel')}
          inputProps={{
            pattern: "^[0-9]{2,3}-? ?[0-9]{6,7}$",
            minLength: 9
          }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Dirección"
          onChange={handleChange('address')}
          name="address"
          value={userProfile.address}
        />

      <Box mt={4} className="distHorizontal" display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Guardar cambios
        </Button>
      </Box>
      </form>
    </IndoContainer>
  )
}

export default Perfil
