import moment from 'moment'
import 'moment/locale/es.js'
import { momentLocalizer } from 'react-big-calendar'

export const maxHours = () => {
  const max = new Date()
  max.setHours(19, 0, 0)
  return max
}

export const minHours = () => {
  const min = new Date()
  min.setHours(7, 0, 0)
  return min
}

export const timeConstraints = { minutes: { step: 30 } }

export const CalConfig = {
  localizer: momentLocalizer(moment),
  max: maxHours(),
  min: minHours(),
  defaultView: 'work_week',
  scrollToTime: new Date(1970, 1, 1, 6),
  views: ['day', 'agenda', 'work_week', 'month'],
  startAccesor: (event) => {
    console.log(event)
    return moment(event.start.toDate())
  }
}

export const hourDiff = (start, end) => Math.abs(end - start) / 36e5;
