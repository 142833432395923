import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// Concurrent mode!

// const rootEl = document.getElementById("root");
// const root = ReactDOM.createRoot(rootEl);
// root.render(<App />);
