import React, { useState, useCallback } from 'react'
import { Formik, Field } from 'formik'
import { useSession } from 'backend/auth'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import { DialogContent, DialogActions, Box, Button, FormControlLabel, Divider, Typography } from '@material-ui/core'
// import FormikDatetime from 'components/calendar/components/FormikDatetime'
import RangeSlider from 'components/calendar/components/RangeSlider'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { setOrCreateCita, deleteEvent } from 'backend/calendar'
import moment from 'moment'
import omit from 'lodash.omit'
// import { isPhone } from 'spain-phone'
import { hourDiff } from 'utils/cal-utils'

const CitaForm = ({event, setLoading, isSubmitted, onClose, Header}) => {
  const user = useSession()
  const { enqueueSnackbar } = useSnackbar()
  const [cita, setCita] = useState(event)
  const confirm = useConfirm()
  const [usedForm, setUsedForm] = useState(false)
  const onChangeForm = useCallback(() => setUsedForm(true), [])
  const [constraints] = useState(() => {
    return {
      min: event.slot ? event.slot.start : event.start,
      max: event.slot ? event.slot.end : event.end
    }
  })

  const onDelete = (values) => {
    deleteEvent(values)
    onClose('Cita eliminada', 'success')
  }

  const isNewCita = event.type === 'disponibilidad'
  const isCita = event.type === 'cita'
  const isUntouchable = cita.confirm && cita.confirm.xEspecialista === true

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...omit(cita, ['email', 'color', 'updatedAt']),
        title: `Nueva cita: ${(!user.isCliente) ? user.profile.name : ''}`,
        slot: cita.slot || cita.id || '',
        especialista: cita.especialista || cita.email,
        cliente: user.email,
        type: 'cita',
        detalles: {
          procedimiento: cita.detalles ? cita.detalles.procedimiento : '',
          observaciones: cita.detalles ? cita.detalles.observaciones : '',
          necesidadesEspeciales: cita.detalles ? cita.detalles.necesidadesEspeciales : false,
          paciente: {
            nombre: cita.detalles && cita.detalles.paciente ? cita.detalles.paciente.nombre : '',
            telefono: cita.detalles && cita.detalles.paciente ? cita.detalles.paciente.telefono : '',
          }
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setLoading(true)

        if (!isNewCita && (typeof values.slot !== 'string' || !values.slot instanceof String)) {
          console.log(values.slot, values.slot.id)
          values.slot = values.slot.id
        }

        // CALCULAR SI HAY QUE AV8ISAR

        if (
          isNewCita &&
          values.detalles.necesidadesEspeciales &&
          hourDiff(values.start, values.end) > 2
        ) {
          try {
            await confirm({
              title: "Se recomienda que, en casos de pacientes con necesidades especiales, las citas no sean superiores a dos horas",
              cancellationText: 'Modificar',
              dialogProps: {
                maxWidth: 'xs',
              }
            })
          } catch {
            setLoading(false)
            setSubmitting(false)
            return
          }
        }

        // ALL THIS
        const item = await setOrCreateCita(values, 'Citas')
        setCita({...item})

        setLoading(false)
        setSubmitting(false)

        enqueueSnackbar('Nueva cita creada con éxito.', {variant: 'success'})
        isSubmitted()
      }}
    >
      {({
        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
        /* and other goodies */
        setFieldValue, setFieldTouched,
        }) => {

          const rangeProps = {
            ...constraints,
            start: values.start,
            end: values.end,
            onChange: setFieldValue,
            onBlur: setFieldTouched,
            setUsedForm: () => setUsedForm(true)
          }

          return (
            <form onChange={onChangeForm} onSubmit={handleSubmit} noValidate>
              <Header title={values.title} start={moment(values.start).format('LL HH:mm')} end={moment(values.end).format('HH:mm')} />
              <DialogContent>
                <Box mb={2}>
                  <Typography variant="h3">Cita</Typography>
                  <RangeSlider {...rangeProps} />

                  <Field
                    name="detalles.procedimiento"
                    variant="outlined"
                    label="Procedimiento"
                    component={TextField}
                    margin="normal"
                    fullWidth
                  />

                  <Field
                    name="detalles.observaciones"
                    variant="outlined"
                    label="Observaciones"
                    component={TextField}
                    margin="normal"
                    fullWidth
                  />

                  <Box px={2} py={1} className="checkbox">
                    <FormControlLabel
                      label="Niños o pacientes con necesidades especiales"
                      control={
                        <Field
                          name="detalles.necesidadesEspeciales"
                          component={CheckboxWithLabel}
                        />
                      }
                    />
                  </Box>
                </Box>

                <Divider />

                <Box mt={4}>
                  <Typography variant="h3">Paciente</Typography>
                  <Field
                    name="detalles.paciente.nombre"
                    variant="outlined"
                    label="Nombre completo"
                    component={TextField}
                    margin="normal"
                    fullWidth
                  />

                  <Field
                    name="detalles.paciente.telefono"
                    variant="outlined"
                    label="Teléfono"
                    component={TextField}
                    margin="normal"
                    fullWidth
                    inputProps={{
                      pattern: "^[0-9]{2,3}-? ?[0-9]{6,7}$",
                      minLength: 9,
                    }}
                  />
                </Box>
              </DialogContent>

              <DialogActions>
                {
                  isCita && !isUntouchable && (
                    <Button color="secondary" onClick={() => onDelete(values)}>
                      Eliminar
                    </Button>
                  )
                }

                {
                  <Button
                    onClick={() => console.log('Hola')}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || isUntouchable || !usedForm}
                  >
                    {
                      isUntouchable ? 'Esperando confirmación' : isNewCita ? 'Crear cita' : 'Modificar cita'
                    }
                  </Button>
                }
              </DialogActions>
            </form>
          )
        }}
    </Formik>
  )
}

export default CitaForm
