export const roleExtractor = (role) => {
  switch (role) {
    case 'esp': return 'Especialista'
    case 'especialista': return 'Especialista'
    case 'adm': return 'Admin'
    case 'admin': return 'Admin'
    default: return 'Cliente'
  }
}

export const isRole = (role) => {
  return role
}
