import React, { useState } from 'react'
import { useFirestoreCollectionData, useFirestore } from "reactfire";
import { useConfirm } from "material-ui-confirm"
import { DialogContent, DialogActions, Box, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core"
// import { ChevronRight } from '@material-ui/icons'
import { useSession } from 'backend/auth'
import { useSnackbar } from 'notistack'
import { aceptarCita, confirmarCita, cerrarCita, rechazarCita, asignarEspecialistaACita } from 'backend/calendar'
import moment from 'moment'
moment.locale('es')


const CitaData = ({
  values,
  Header,
  onClose,
  setLoading
}) => {
  const confirm = useConfirm()
  const user = useSession()
  const { enqueueSnackbar } = useSnackbar()

  const [asignando, setAsignando] = useState(false)
  const [asignado, setAsignado] = useState('')


  let espisRef = useFirestore()
    .collection("users")
    .where("role", "==", "esp")
  let espis = useFirestoreCollectionData(espisRef);

  if(!values || !espis) return null

  const handleCita = async (action, data) => {
    setLoading(true)
    action(values.id, data)
      .then(({status, message}) => {
        message && enqueueSnackbar(message, { variant: status })
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  const handleRechazo = async () => {
    let conservarDisponibilidad
    await confirm({
      title: "¿Quieres rechazar esta cita?",
      dialogProps: {
        maxWidth: 'xs'
      }
    })
    const razones = window.prompt("Por favor, especifica tus razones")

    confirm({
      title: "¿Deseas conservar tu disponibilidad?",
      confirmationText: "Sí, conservar",
      cancellationText: "No",
      dialogProps: {
        maxWidth: 'xs'
      }
    }).then(() => conservarDisponibilidad = true)
      .catch(() => conservarDisponibilidad = false)
      .finally(async () => {
        await handleCita(rechazarCita, { razones, conservarDisponibilidad, especialista: user.email, cliente: values.cliente })
      })
  }

  const asignarEspecialista = async (e) => {
    e.preventDefault()
    await confirm({ title: `¿Quieres asignar a ${asignado}?` })
    await handleCita(asignarEspecialistaACita, asignado )
  }

  console.log(values)

  return (
    <>
      <DialogContent style={{ padding: 0 }}>
        <Header title={values.title} />
        <TableContainer>
          <Table aria-label="cita">
            <TableBody>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell align="right">
                  {moment(values.start).format('dddd').capitalize()},{' '}
                  {moment(values.start).format('LL')}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Hora</TableCell>
                <TableCell align="right">
                  {moment(values.start).format('LT')} -{' '}
                  {moment(values.end).format('LT')}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell align="right">{values.cliente}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Niño o paciente con necesidades especiales
                </TableCell>
                <TableCell align="right">
                  {values.detalles && values.detalles.necesidadesEspeciales
                    ? 'Sí'
                    : 'No'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Procedimiento</TableCell>
                <TableCell align="right">
                  {values.detalles && values.detalles.procedimiento}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Observaciones</TableCell>
                <TableCell align="right">
                  {values.detalles && values.detalles.observaciones}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Paciente: nombre</TableCell>
                <TableCell align="right">
                  {values.detalles && values.detalles.paciente && values.detalles.paciente.nombre}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Paciente: teléfono</TableCell>
                <TableCell align="right">
                  {values.detalles && values.detalles.paciente && values.detalles.paciente.telefono}
                </TableCell>
              </TableRow>

              {user.profile.role === 'admin' && values.especialistas && (
                <TableRow>
                  <TableCell>Especialistas (sin asignar)</TableCell>
                  <TableCell align="right">
                    {values.especialistas
                      .map((esp) => esp.especialista)
                      .join(', ')}
                  </TableCell>
                </TableRow>
              )}

              {user.profile.role === 'admin' && values.especialista && (
                <TableRow>
                  <TableCell>Especialista asignado</TableCell>
                  <TableCell align="right">{values.especialista}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Box mt={4} display="flex" justifyContent="flex-end">
          {/* <Button color="secondary" onClick={() => console.log('📅 Denegada')}>
            Denegar
          </Button> */}
          {user.profile.role === undefined ||
          user.profile.role === 'cli' ||
          user.profile.role === 'cliente' ? (
            // Aquí somos un cliente
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCita(confirmarCita)}
              disabled={values.confirm && values.confirm.xCliente}
            >
              {values.confirm.xCliente !== true
                ? 'Confirmar cita'
                : 'Cita confirmada'}
            </Button>
          ) : user.profile.role === 'esp' ? (
            // Aquí somos un especialista
            <>
              <Button
                color="secondary"
                style={{ marginRight: '.5em' }}
                onClick={handleRechazo}
                disabled={
                  values.confirm &&
                  (values.confirm.xEspecialista ||
                    values.confirm.xAdmin ||
                    (values.confirm.cancel &&
                      values.confirm.cancel[user.email]))
                }
                //disabled={values.confirm.xEspecialista === true}
              >
                Rechazar cita
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCita(aceptarCita)}
                disabled={
                  values.confirm && values.confirm.xEspecialista
                  // (values.confirm.cancel || values.confirm.xEspecialista)
                }
                //disabled={values.confirm.xEspecialista === true}
              >
                {values.confirm && values.confirm.xEspecialista
                  ? 'Cita aceptada'
                  : 'Aceptar cita'}
              </Button>
            </>
          ) : (
            // Aquí somos un admin o un superadmin
            <Box
              component="form"
              display="flex"
              alignItems="baseline"
              justifyContent="flex-end"
              className="distHorizontal"
              onSubmit={asignarEspecialista}
              noValidate
            >
              {((values.especialistas && asignando) ||
                values.confirm.cancel) && (
                <>
                  <FormControl style={{ marginRight: '.5em', maxWidth: 240 }}>
                    <InputLabel id="asignar">Especialista</InputLabel>
                    <Select
                      id="asignar"
                      value={asignado}
                      onChange={(e) => setAsignado(e.target.value)}
                    >
                      {espis &&
                        espis.map((esp) => (
                          <MenuItem value={esp.email}>{esp.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <Button
                    color="secondary"
                    type="submit"
                    style={{ marginRight: '.5em' }}
                  >
                    Asignar
                  </Button>
                </>
              )}
              {values.especialistas &&
                !asignando &&
                values.confirm &&
                (!values.confirm.xEspecialista || !values.confirm.xAdmin) && (
                  <Button
                    color="secondary"
                    onClick={() => setAsignando(true)}
                    style={{ marginRight: '.5em' }}
                  >
                    Asignar especialista
                  </Button>
                )}

              {values.confirm && values.confirm.xCliente && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCita(cerrarCita)}
                  disabled={
                    values.confirm &&
                    (values.confirm.xAdmin || !values.confirm.xCliente)
                  }
                >
                  {!(values.confirm && values.confirm.xAdmin)
                    ? 'Cerrar cita'
                    : 'Cita cerrada'}
                </Button>
              )}

              {values.confirm && !values.confirm.xCliente && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCita(cerrarCita)}
                  disabled
                >
                  Esperando a cliente
                </Button>
              )}
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  )
}

export default CitaData
