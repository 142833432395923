import React from 'react'
import { useSessionWithRole } from 'backend/auth'

import { Typography, IconButton, FormControl, FormControlLabel, InputLabel, Select, MenuItem, Box, Checkbox } from '@material-ui/core'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons'
// import Doctors from 'components/UI/Icons/DoctorsIcon'

const VIEW = {
  'day': 'Día',
  'agenda': 'Agenda',
  'work_week': 'Semana',
  'month': 'Mes'
}

const CalToolbar = (props) => {
  const { view, views, label } = props
  const { espis, setEspis } = props
  const handleChange = (e) => props.onView(e.target.value)
  const [, role] = useSessionWithRole()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" p={4} flex="2">
        <IconButton size="small" onClick={() => props.onNavigate('PREV')}><KeyboardArrowLeft /></IconButton>
        <Typography component="h1" variant="h5" className="capitalize">{label}</Typography>
        <IconButton size="small" onClick={() => props.onNavigate('NEXT')}><KeyboardArrowRight /></IconButton>
      </Box>

      <Box display="flex" className="distHorizontal" justifyContent="flex-end" alignItems="flex-end" flex="0">
        <Box>
          <FormControl>
            <InputLabel>Vista</InputLabel>
            <Select value={view} onChange={handleChange}>
              {views.map((value) => <MenuItem key={value} value={value}>{VIEW[value]}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>

        {
          role && role.includes('esp') && (
            <Box>
              <FormControlLabel
                style={{ whiteSpace: 'nowrap' }}
                control={<Checkbox checked={espis} onChange={() => setEspis(!espis)} />}
                label="Ver otros especialistas"
              />
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

export default CalToolbar
