import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Home, AccountCircle  } from '@material-ui/icons'

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 25%, ${theme.palette.primary.light} 75%, ${theme.palette.primary.main} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 25%, ${theme.palette.primary.light} 75%, ${theme.palette.primary.main} 100%)`,
  },
}))

export default function ColorlibStepIcon({ active, completed, icon }) {
  const classes = useColorlibStepIconStyles()
  const icons = { 1: <Home />, 2: <AccountCircle /> }
  return (
    <div className={clsx(classes.root, { [classes.active]: active, [classes.completed]: completed,})}>
      {icons[String(icon)]}
    </div>
  )
}
