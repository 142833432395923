import firebase from 'config/fb'
import React, { useContext, useEffect } from 'react'

export const userContext = React.createContext({ user: null, initialising: false, error: '' })

export const useSession = () => {
  const { user } = useContext(userContext)
  return user
}

export const useSessionWithRole = () => {
  const { user } = useContext(userContext)
  return [user, user.profile.role]
}

export const useSessionWhichRole = () => {
  // const { user, initialising, error } = useContext(userContext)
  const { user } = useContext(userContext)
  if (user && user.profile) {

    user.isCliente = !user.profile.role || user.profile.role.includes('cli')
    user.isEspecialista = user.profile.role !== undefined && user.profile.role.includes('esp')
    user.isAdmin = user.profile.role !== undefined && user.profile.role.includes('adm')

    return [user, user.profile.role]
  }
  return [user]
}

export const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser
    return { initializing: !user, user, error: '' }
  })

  const OnChange = async (user) => {
    if(user) {
      const result = await firebase.firestore().collection('users').doc(user.uid).get()
      if(result.exists) user.profile = result.data()
      console.log('🐱‍👓 Our user: ', user)
    }
    setState({ initializing: false, user })
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(OnChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}

export const loginWithEmail = async (email, password) => {
  try {
    // const results = await firebase
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const createUserWithEmail = async (email, password) => {
  try {
    const result = await firebase.auth().createUserWithEmailAndPassword(email, password)
    console.log('👤 User created!')
    return result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const addUserDetails = async(id, details) => {
  try {
    await firebase.firestore().collection('users').doc(id).set(details)
    console.log('👥 User details added!')
  } catch(err) {
    console.error(err)
    throw err
  }
}

export const signOut = async () => await firebase.auth().signOut()

export const forgotPassword = (email) => (firebase.auth().sendPasswordResetEmail(email))

export const getUserByEmail = async (email) => {
  const returnUserByEmail = firebase.functions().httpsCallable('returnUserByEmail')
  const user = await returnUserByEmail({email})
  return user.data()
}
