import React from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

import { FirebaseAppProvider } from "reactfire";

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "dolorosa-9eb85.firebaseapp.com",
  databaseURL: "https://dolorosa-9eb85.firebaseio.com",
  projectId: "dolorosa-9eb85",
  storageBucket: "dolorosa-9eb85.appspot.com",
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
}

console.log(process.env.NODE_ENV)

firebase.initializeApp(firebaseConfig)
// if (process.env.NODE_ENV === 'development') {
//   firebase.functions().useFunctionsEmulator('http://localhost:5001');
// }
firebase.firestore()

export default firebase

export const Firebase = ({children}) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      {children}
    </FirebaseAppProvider>
  );
}

export const getEntry = (id, collection) => (
  firebase.firestore().collection(collection).doc(id).get()
)

// const convertDate = (date) => firebase.firestore.Timestamp.fromDate(date)

export const createEntry = (item, collection) => (
  firebase.firestore()
    .collection(collection).add({
      ...item,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
)

export const updateEntry = (id, item, collection) => (
  firebase.firestore()
    .collection(collection)
    .doc(id)
    .update({
      ...item,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
)

export const updateField = (id, field, value, collection) => (
  firebase.firestore()
    .collection(collection)
    .doc(id)
    .update({
      [field]: value,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
)

export const deleteEntry = (id, collection) => (
  firebase.firestore()
    .collection(collection)
    .doc(id)
    .delete()
)
