import React, { useState, useEffect } from 'react'
import { useRoute, useLocation } from 'wouter'

import { useSessionWhichRole } from 'backend/auth'
import { useTheme } from '@material-ui/styles'
import { useCal, createSlot, alien } from 'backend/calendar'
import { updateEntry } from 'config/fb'

// Calendar. Other comps: DateLocalizer, globalizeLocalizer, move, Views, Navigate, components,
import { Calendar } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'styles/Calendar.scss'

import CalToolbar from './CalToolbar'
import { CalConfig } from 'utils/cal-utils'

import IndoContainer from 'components/UI/IndoContainer'

import moment from 'moment'
import { useModal } from 'react-modal-hook'
import { useSnackbar } from 'notistack'
import { useConfirm } from "material-ui-confirm";

import EditEvent from 'components/calendar/modals/EditEvent'
import Cita from 'components/calendar/modals/Cita'
import Leyenda from 'components/calendar/components/Leyenda'

const Calendario = () => {
  const theme = useTheme()
  const [user, role] = useSessionWhichRole()
  const [cal] = useCal()
  const [events, setEvents] = useState(cal)
  const [, setView] = useState("work_week");
  const confirm = useConfirm();

  // Especialista work
  const [espis, setEspis] = useState(true)


  useEffect(() => {
    const evs = user.isEspecialista && !espis
          ? cal.filter(ev => ev.type.includes('cita') || (ev.type.includes('disp') && ev.email === user.email) )
          : cal

    setEvents(evs)
  }, [cal, espis, user])

  // Prepare the snackbar
  const { enqueueSnackbar } = useSnackbar()
  const [date, setDate] = React.useState(new Date())

  // Set the state
  const [ currentEvent, setCurrentEvent] = React.useState()
  const getCurrentEvent = React.useCallback((id) => events.find(ev => ev.id === id), [events])

  /* Modals! */
  const [showEditModal, hideEditModal] = useModal( ({ in: open, onExited }) => (
    <EditEvent
      open={open}
      current={currentEvent}
      onExited={onExited}
      onClose={onHideModal(hideEditModal)}
    />
  ), [currentEvent])

  const [showCitaModal, hideCitaModal] = useModal( ({ in: open, onExited }) => {
    return (
      <Cita
        className="CitaModal"
        open={open}
        current={currentEvent}
        onExited={onExited}
        onClose={onHideModal(hideCitaModal)}
      />
    )
  }, [currentEvent])

  /* Router! */
  // Get info from router
  const [match, params] = useRoute('/calendario/citas/:id*')
  const [, setLocation] = useLocation()

  // Show modal every time cita is in URL
  useEffect(() => {
    if(match) {
      setCurrentEvent(getCurrentEvent(params.id))
      showCitaModal()
    }
  }, [match, getCurrentEvent, params, showCitaModal])

  const onSelectEvent = (event) => {
    const { start, type, id, email } = event

    setDate(start)
    if(type.includes('cita')) {
      // console.log('')
      setLocation(`/calendario/citas/${id}`)
    }

    if (user.isEspecialista && user.email !== email) return

    else if(type.includes('disponibilidad')) {
      // Disponibilidad
      setCurrentEvent( getCurrentEvent(id) )
      if (role && ((role.includes('esp') && email === user.email) || role.includes('admin'))) {
        // Soy un especialista o un admin, y quiero editar disponibilidad
        showEditModal()
      } else {
        // Soy un cliente, y quiero crear una cita
        showCitaModal()
      }

    }
  }

  /* Functions! */
  const onSelectSlot = async (slot) => {
    // If calendar is month
    if (document.querySelector('.rbc-month-row')) {
      enqueueSnackbar("Sólo se puede crear disponibilidad en la vista semanal", { variant: "info" })
      return
    }
    // Check it is not a simple click
    if (slot.action === 'click') return
    // You're out if you have no permissions
    if (!role || !slot.start) return

    if (role.includes('admin')) {
      const espimail = window.prompt("Introduce el email del especialista");
      if (!espimail) return
      try {
        await confirm({
          title: `¿Quieres crear un slot de disponibilidad para ${espimail}?`,
          description: `Con horario de ${moment(slot.start).format(
            "HH:mm"
          )} a ${moment(slot.end).format("HH:mm")}`,
          dialogProps: {
            maxWidth: "xs",
          },
        });
        await createSlot(slot, espimail, "disponibilidad");
        setDate(slot.start);
        enqueueSnackbar(
          `Disponibilidad creada para ${espimail}`,
          { variant: "success" }
        );
      } catch(err) {
        const msg = err ? `Error ${espimail}: ${err.message}` : `Disponibilidad no creada`
        const variant = err ? 'error' : 'warning'
        enqueueSnackbar(msg, { variant });
        return
      }
    } else if (role.includes('esp')) {
      await createSlot(slot, user.email, "disponibilidad");
      setDate(slot.start);
      enqueueSnackbar(`Disponibilidad creada`, {
        variant: "success",
      });
    }
  }

  const onUpdate = (event, start, end) => {
    if (alien(event.email, user.email)) return
    updateEntry(event.id, { ...event, start, end }, 'disponibilidad')
  }

  const onHideModal = (func) => (snackMessage, variant) => {
    setLocation('/calendario')
    snackMessage
      && typeof snackMessage === String
      && enqueueSnackbar(snackMessage, {variant})
    func && func()
  }

  const eventColorizer = (event, start, end, isSelected) => {
    // console.log(event)
    const isCita = event.type === 'cita'
    let email = isCita ? event.especialista : event.email
    // If not my email
    const color = (alien(event.email, user.email)) ? event.color : theme.palette.primary.main

    var style = {
      backgroundColor: color,
      borderColor: color,
    }

    isCita && (style.borderRadius = 0)


    // classGame
    let className = ''

    // Cita o disponibilidad?
    className += isCita ? 'cita ' : 'disponibilidad '

    // Es de otro o es mía?
    className += (!user.isClente && alien(email, user.email)) ? ' alien ' : ' native '

    // Está bloqueada o no?
    event.lock && (className += 'event-lock ')

    // Necesidades especiales?
    className += (isCita && event.detalles && event.detalles.necesidadesEspeciales) ? 'babyCita ' : ''

    // Me toca a mí?
    if (isCita && event.next) {
      // Si soy admin y el evento me señala a mí:
      if (user.isAdmin && event.next === 'admin') {
        className += ' myTurn '
      }

      if (user.isEspecialista &&  event.next === user.email) {
        className += ' myTurn '
      }
    }

    // Aceptada?
    className += (isCita && event.confirm && event.confirm.xEspecialista && event.especialista === user.email) && ' aceptada '

    // Confirmada?
    className += (isCita && event.confirm && event.confirm.xCliente) ? ' confirmada ' : ''

    if (isCita && event.confirm && event.confirm.cancel) {
      if (
        (event.especialista === user.email && event.confirm.cancel[user.email])
        || (user.isAdmin && event.confirm.cancel && !event.confirm.xEspecialista)
      ) className += ' rechazada '
    }

    // className += (isCita && event.confirm && event.confirm.cancel) ? ' rechazada ' : ''

    className += ' ' + event.id + ' '

    return {
      style, className
    }
  }


  const Cal = role && user.isEspecialista
              ? withDragAndDrop(Calendar)
              : Calendar

  return (
    <IndoContainer paper maxWidth="md" full>
      <Cal
        {...CalConfig}
        defaultDate={date}
        events={events}
        className={role && role.includes("esp") && "CalEspecialista"}
        onView={view => setView(view)}
        eventPropGetter={eventColorizer}
        onSelectSlot={slot => onSelectSlot(slot)}
        onSelectEvent={event => onSelectEvent(event)}
        onEventResize={({ event, start, end }) => onUpdate(event, start, end)}
        onEventDrop={({ event, start, end }) => onUpdate(event, start, end)}
        // components={{ toolbar: CalToolbar }}
        components = {{ toolbar: props => ( <CalToolbar {...props} setView={(view) => setView(view)} espis={espis} setEspis={setEspis} /> ) }}
        selectable={role && true}
        resizable
      />

      <Leyenda />
    </IndoContainer>
  );
}
export default Calendario
