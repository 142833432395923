import React, { useState } from 'react'
import { TextField, Link } from '@material-ui/core'
import Places from './Registro-Places'
import {TextFieldProps} from './registro-utils'

const Direccion = ({ setPartialRegister, children }) => {
  const [place, setPlace] = useState({
    suggestion: null,
    noPlace: false,
    name: '',
    address: '',
  })

  const setNoPlace = () => setPlace({
    suggestion: null,
    noPlace: true,
    name: '',
    address: ''
  })

  const changePlace = prop => event => setPlace({ ...place, [prop]: event.target.value })
  // eslint-disable-next-line
  React.useEffect(() => setPartialRegister(place), [place])

  return (
    <>
      {
        place.noPlace || (
          <>
            <Places setSuggestion={setPlace} />
            <Link onClick={setNoPlace}>Haga click si no encuentra su clínica</Link>
          </>
        )
      }

      <div className={ place.suggestion || place.noPlace ||  'invisible' }>
        <TextField
          {...TextFieldProps}
          label="Nombre"
          value={place.name}
          onChange={changePlace('name')}
          required
          autoFocus
        />
        <TextField
          {...TextFieldProps}
          label="Dirección"
          value={place.address}
          onChange={changePlace('address')}
          required
        />
        {children}
      </div>
    </>
  )
}

export default Direccion
