import React from 'react'
import { Container, Box, Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'wouter'
import { MiniImg } from 'styles/StyledComponents'
import JoyRide from 'assets/graphics/undraw_joyride.svg'

export default () => (
  <Container component="main" maxWidth="xs">
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <MiniImg src={JoyRide} alt="404"/>
      <Typography variant="h1">
        Ruta no encontrada!
      </Typography>

      <Box pt={4} pb={4} display="flex" justifyContent="center">
        <Link component={RouterLink} to="/">🠐 Volvamos a Indolora!</Link>
      </Box>
    </Box>
  </Container>
)
