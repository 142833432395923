import React, { Suspense } from "react";
import firebase from 'config/fb'
import { hot } from 'react-hot-loader/root'
import { Switch, Route, Redirect, useLocation } from 'wouter'
import { Firebase } from "config/fb";
import { writeStorage, useLocalStorage, deleteFromStorage } from '@rehooks/local-storage'

// FIREBASE
import { userContext, useAuth } from 'backend/auth'
import { CalProvider } from 'backend/calendar'

// Utils
import 'utils/type'

// Styles
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from 'styles/MaterialTheme'

import GlobalStyles from 'styles/GlobalStyles'
import { ConfirmProvider } from "material-ui-confirm";
import { ModalProvider } from 'react-modal-hook'
import { SnackbarProvider } from 'notistack'
import { TransitionGroup } from "react-transition-group";
import IndoSpinner from 'components/UI/IndoSpinner'

// Components
import SuperNav from 'components/nav/SuperNav'

// Pages
import IniciarSesion from 'components/auth/IniciarSesion'
import Registro from 'components/auth/registro/Registro'
import NoPassword from 'components/auth/NoPassword'
import Indo404 from 'components/404'
import Perfil from 'components/users/Profile'
import Calendario from 'components/calendar/Calendario'
import Invitados from 'components/admin/Invitados'
import RegistroEspecialistas from 'components/auth/registro/Registro-Especialistas'
import Especialistas from "components/admin/Especialistas"
import Clientes from "components/admin/Clientes"

const MaterialTheme = createMuiTheme(theme)

// TODO: cambiar por AuthCheck
const PrivateRoute = ({ component: Component, path, ...other }) => {
  const user = firebase.auth().currentUser
  const [location, setLocation] = useLocation()
  const [Cthulhu] = useLocalStorage('Cthulhu')

  // React.useEffect(() => {
    if (!user && location !== '/') {
    writeStorage('Cthulhu', location)
    setLocation('/')
  }

  if (user && Cthulhu) {
    deleteFromStorage('Cthulhu')
    setLocation(Cthulhu)
  }
  // }, [])

  if (!user) return <Redirect to="/" />
  return <Component />
}

const App = () => {
  const { user, initializing, error } = useAuth()

  if (initializing) return <IndoSpinner />
  if (error) return <div><p>Error: {error}</p></div>

  return (
    <Firebase>
      <Suspense fallback={<IndoSpinner />}>
        <userContext.Provider value={{ user, initializing }}>
          <ThemeProvider theme={MaterialTheme}>
            <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
              <ConfirmProvider>
                <ModalProvider container={TransitionGroup}>
                  <CalProvider>
                    {/* Styles! */}
                    <CssBaseline />
                    <GlobalStyles />

                    {/* The rest! */}
                    <div className="App">
                      {/* Auth */}
                      <Switch>
                        <Route path="/" component={IniciarSesion} />
                        <Route
                          path="/iniciar-sesion"
                          component={IniciarSesion}
                        />
                        <Route path="/registro" component={Registro} />
                        <Route
                          path="/registro-especialistas"
                          component={RegistroEspecialistas}
                        />
                        {/* <Route path="/test">

                        </Route> */}
                        {/* Users */}
                        <PrivateRoute path="/perfil" component={Perfil} />
                        <PrivateRoute path="/invitados" component={Invitados} />
                        <PrivateRoute
                          exact
                          path="/lista/especialistas"
                          component={Especialistas}
                        />
                        <PrivateRoute
                          exact
                          path="/lista/clientes"
                          component={Clientes}
                        />
                        {/* Main! */}
                        <PrivateRoute
                          path="/calendario"
                          component={Calendario}
                        />
                        <PrivateRoute
                          path="/calendario/citas/:id*"
                          component={Calendario}
                        />
                        {/* <Route path='/calendario/citas/:id'>
                          {params => <Calendario cita={params.id} />}
                        </Route */}
                        >
                        <Route path="/no-password" component={NoPassword} />
                        <Route path="/spinner" component={IndoSpinner} />
                        <Route path="/:rest*" component={Indo404} />
                      </Switch>

                      {user && <SuperNav />}
                    </div>
                  </CalProvider>
                </ModalProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </userContext.Provider>
      </Suspense>
    </Firebase>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App
