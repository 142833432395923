import React from 'react'
import firebase, { deleteEntry, createEntry } from 'config/fb'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { IconButton, Button, Box, Paper, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import IndoContainer from 'components/UI/IndoContainer'
import IndoHeader from 'components/UI/IndoHeader'

const Invitado = ({email, id = 0}) => {
  return (
    <TableRow>
      <TableCell>{email}</TableCell>
      <TableCell align="right">
        <IconButton size="small" color="secondary" onClick={() => deleteEntry(id, 'invitados')}>
          <Delete fontSize="inherit" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const Invitados = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [email, setEmail] = React.useState('')
  const [values, loading, error] = useCollectionData(
    firebase.firestore().collection('invitados'),
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )

  const onSubmit = async (e) => {
    e.preventDefault()
    const invitado = await createEntry({email}, 'invitados')
    if(invitado) enqueueSnackbar('OK!', {variant: 'success'})
  }

  if(error || loading) return null

  if(error) enqueueSnackbar('Error. Prueba otra vez.', {variant: 'error'})

  return (
    <IndoContainer maxWidth="md">
      <Box display="flex" alignItems="center" justifyContent="stretch">
        <Paper width="100%" style={{ flex: 1 }}>
          <Table>
            <TableBody>
              {values && values.map(({email, id}, i) => {
                return (
                  <Invitado key={i} email={email} id={id}/>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
        <Box p={8} style={{ flex: 1 }}>
          <IndoHeader icon="lock" title="Invitar especialista" />
          <Box component="form" onSubmit={onSubmit}>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
            >Invitar</Button>
          </Box>
        </Box>
      </Box>
    </IndoContainer>
  )
}

export default Invitados
